import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';

import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
    ActivityGroupData,
    ActivityGroupChildActivity,
} from '../../interfaces/activityGroup';
import { DATE_PICKER_VALUE_FORMAT } from '../../../common/utils/cloudscapeHelpers';
import { DeliveryInstructor, DeliverySession } from '../../interfaces/activity';
import { getActivityGroupData } from '../../services/activity-service';
import { AppDispatch, GlobalState } from '../../../main/store';
import {
    AciDeliverySessionType,
    ACTIVITY_GROUP_NAME_EMBEDDED_VALUE_DELIMITER,
} from '../../constants/aci';
import { InstructorProfileData } from '../../../common/interfaces/instructorProfile';
import { ActivityStatus } from '../../components/Activity/Common/Common';
import { selectUser } from '../../../common/store/slices/userSlice';
import { V_ILT_TYPE } from '../../constants';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

const DEFAULT_CLASS_DAYS = 90;

const guessedTimezone = dayjs.tz.guess();
const today = dayjs().tz(guessedTimezone).startOf('day');
const defaultEndDay = today.add(DEFAULT_CLASS_DAYS, 'days');

const initialState: ActivityGroupData = {
    is_loading: false,
    name: '',
    active: true,
    status: 'Hold',
    start_timestamp: today.unix(),
    end_timestamp: defaultEndDay.unix(),
    selected_timezone: guessedTimezone,
    program_name: '',
    course_name: '',
    catalog_item_id: '',
    catalog_item_versioned_id: '',
    child_activities: [],
    instructor_pool: [],
    delivery_map: {},
};

export const fetchActivityGroupByName = createAsyncThunk<
    ActivityGroupData,
    string,
    { state: GlobalState; dispatch: AppDispatch }
>('activityGroup/fetchByName', async (name: string, thunkApi) => {
    const { activityGroup, childActivities } = await getActivityGroupData(name);

    const instructor_pool = childActivities.reduce<DeliveryInstructor[]>(
        (acc, curr) => {
            curr.instructors?.forEach((i) => {
                const isIncluded = acc.find((existing) => existing.pk === i.pk);
                if (!isIncluded) acc.push(i);
            });

            return acc;
        },
        [],
    );

    const child_activities = childActivities.map<ActivityGroupChildActivity>(
        (activity) => {
            const [_, delivery_name, option_number] =
                activity.activity_name.split(
                    ACTIVITY_GROUP_NAME_EMBEDDED_VALUE_DELIMITER,
                );

            return {
                ...activity,
                delivery_name,
                option_number: parseInt(option_number),
            };
        },
    );

    const delivery_map = child_activities.reduce((acc, curr) => {
        if (curr.delivery_name in acc) {
            acc[curr.delivery_name].push(curr.activity_name);
        } else {
            acc[curr.delivery_name] = [curr.activity_name];
        }

        return acc;
    }, {});

    return {
        ...thunkApi.getState().activityGroup,
        ...activityGroup,
        is_loading: false,
        child_activities,
        delivery_map,
        instructor_pool,
    };
});

export const activityGroupSlice = createSlice({
    name: 'activityGroup',
    initialState,
    reducers: {
        setActivityGroup: (_, action: PayloadAction<ActivityGroupData>) => {
            return action.payload;
        },
        clearActivityGroup: () => {
            return initialState;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            return { ...state, is_loading: action.payload };
        },
        setActivityGroupStatus: (
            state,
            action: PayloadAction<ActivityStatus>,
        ) => {
            return {
                ...state,
                status: action.payload,
                child_activities: state.child_activities.map((child) => ({
                    ...child,
                    activity_status: action.payload,
                })),
            };
        },
        setCourse: (
            state,
            action: PayloadAction<
                Pick<
                    ActivityGroupData,
                    | 'catalog_item_id'
                    | 'catalog_item_versioned_id'
                    | 'course_name'
                >
            >,
        ) => {
            return { ...state, ...action.payload };
        },
        setStartTimeStampFromDatePickerValue: (
            state,
            action: PayloadAction<{ value: string; selectedTimezone: string }>,
        ) => {
            state.start_timestamp = dayjs
                .tz(
                    action.payload.value,
                    DATE_PICKER_VALUE_FORMAT,
                    action.payload.selectedTimezone,
                )
                .unix();
        },
        setEndTimeStampFromDatePickerValue: (
            state,
            action: PayloadAction<{ value: string; selectedTimezone: string }>,
        ) => {
            state.end_timestamp = dayjs
                .tz(
                    action.payload.value,
                    DATE_PICKER_VALUE_FORMAT,
                    action.payload.selectedTimezone,
                )
                .unix();
        },
        addDeliveryName: (state, action: PayloadAction<string>) => {
            state.delivery_map[action.payload] = [];
        },
        addDeliveryOption: (
            state,
            action: PayloadAction<{
                deliveryName: string;
                operationsOwnerName: string;
                operationsOwnerEmail: string;
            }>,
        ) => {
            const mostRecentOptionNumber = state.delivery_map[
                action.payload.deliveryName
            ].length
                ? parseInt(
                      state.delivery_map[action.payload.deliveryName]
                          .at(-1)
                          .split(ACTIVITY_GROUP_NAME_EMBEDDED_VALUE_DELIMITER)
                          .at(-1),
                  )
                : 0;
            const newOptionNumber = mostRecentOptionNumber
                ? mostRecentOptionNumber + 1
                : 1;
            const deliveryOptionActivityName =
                `${state.name}${ACTIVITY_GROUP_NAME_EMBEDDED_VALUE_DELIMITER}` +
                `${action.payload.deliveryName}${ACTIVITY_GROUP_NAME_EMBEDDED_VALUE_DELIMITER}${newOptionNumber}`;
            state.delivery_map[action.payload.deliveryName].push(
                deliveryOptionActivityName,
            );

            const [existingChildName] =
                state.delivery_map[action.payload.deliveryName];
            const existingChild =
                existingChildName &&
                state.child_activities.find(
                    (ca) => ca.activity_name === existingChildName,
                );

            const defaultSessionStart = dayjs
                .unix(state.start_timestamp)
                .startOf('day')
                .set('hours', 9);
            const defaultSessionEnd = defaultSessionStart.add(4, 'hours');
            const defaultDeliverySession: DeliverySession = {
                start_timestamp: defaultSessionStart.unix(),
                end_timestamp: defaultSessionEnd.unix(),
                delivery_session_type: AciDeliverySessionType.LABOLOGY,
                instructors: [],
                v_ilt_info: {
                    type: V_ILT_TYPE.PVILT,
                },
            };

            if (existingChild) {
                const { pk, ...copiedAttributes } = existingChild;

                state.child_activities.push({
                    ...copiedAttributes,
                    pk: '',
                    activity_name: deliveryOptionActivityName,
                    activity_group_name: state.name,
                    course_name: state.course_name,
                    option_number: newOptionNumber,
                    delivery_sessions: [],
                });
            } else {
                const activityToCopy = state.child_activities.find(
                    (activity) => activity.delivery_city,
                );

                state.child_activities.push({
                    delivery_timezone: '',
                    delivery_city: '',
                    delivery_state: '',
                    delivery_country: '',
                    delivery_region: '',
                    delivery_geo: '',
                    activity_type: '',
                    activity_audience: '',
                    activity_modality: '',
                    customer_support_manager: '',
                    customer_support_manager_email: '',
                    topic_name: '',
                    partner_initiative: '',
                    delivery_language: '',
                    scheduler: '',
                    scheduler_email: '',
                    ...activityToCopy,
                    activity_name: deliveryOptionActivityName,
                    activity_group_name: state.name,
                    activity_status: state.status,
                    class_size: 0,
                    course_catalog_item_id: state.catalog_item_id,
                    course_name: state.course_name,
                    course_days: 0,
                    delivery_sessions: [defaultDeliverySession],
                    instructors: [],
                    lms_type: 'ACI',
                    operations_owner: action.payload.operationsOwnerName,
                    operations_owner_email: action.payload.operationsOwnerEmail,
                    pk: '',
                    program: state.program_name,
                    provider: 'AWS',
                    delivery_name: action.payload.deliveryName,
                    option_number: newOptionNumber,
                });
            }
        },
        setDeliveryOption: (
            state,
            action: PayloadAction<{
                deliveryName: string;
                optionNumber: number;
                start_timestamp?: number;
                end_timestamp?: number;
                class_size?: number;
                pk?: string;
            }>,
        ) => {
            const {
                deliveryName,
                optionNumber,
                start_timestamp,
                end_timestamp,
                class_size,
                pk,
            } = action.payload;
            const optionActivity = state.child_activities.find(
                (activity) =>
                    activity.delivery_name === deliveryName &&
                    activity.option_number === optionNumber,
            );
            const filteredActivities = state.child_activities.filter(
                (activity) => activity !== optionActivity,
            );

            state.child_activities = [
                ...filteredActivities,
                {
                    ...optionActivity,
                    ...(pk ? { pk } : {}),
                    ...(start_timestamp ? { start_timestamp } : {}),
                    ...(end_timestamp ? { end_timestamp } : {}),
                    ...(typeof class_size !== 'undefined'
                        ? { class_size }
                        : {}),
                },
            ];
        },
        addInstructorToPool: (
            state,
            action: PayloadAction<InstructorProfileData>,
        ) => {
            const instructorPoolPks = state.instructor_pool.map((i) => i.pk);
            if (!instructorPoolPks.includes(action.payload.pk)) {
                state.instructor_pool.push({
                    ...action.payload,
                    name: action.payload.full_name,
                    type: action.payload.instructor_type,
                    location: action.payload.city,
                    role: null,
                    do_not_shuffle: false,
                });
            }
        },
        removeInstructorFromPool: (
            state,
            action: PayloadAction<{ pk: string }>,
        ) => {
            state.instructor_pool = state.instructor_pool.filter(
                (instructor) => instructor.pk !== action.payload.pk,
            );
        },
        addInstructorToDeliverySession: (
            state,
            action: PayloadAction<{
                deliveryName: string;
                optionNumber: number;
                instructor: DeliveryInstructor;
                deliverySessionId: string;
            }>,
        ) => {
            const {
                deliveryName,
                optionNumber,
                instructor,
                deliverySessionId,
            } = action.payload;
            const sessionActivity = state.child_activities.find(
                (a) =>
                    a.delivery_name === deliveryName &&
                    a.option_number === optionNumber,
            );
            const targetSession = sessionActivity.delivery_sessions.find(
                (ds) => ds.id === deliverySessionId,
            );
            targetSession.instructors = [
                ...targetSession.instructors.filter(
                    (i) => i.pk !== instructor.pk,
                ),
                instructor,
            ];
            sessionActivity.delivery_sessions = [
                ...sessionActivity.delivery_sessions.filter(
                    (ds) => ds.id !== deliverySessionId,
                ),
                targetSession,
            ];
            sessionActivity.instructors = [
                ...sessionActivity.instructors.filter(
                    (i) => i.pk !== instructor.pk,
                ),
                {
                    ...instructor,
                    role: 'Primary',
                    do_not_shuffle: false,
                },
            ];

            state.child_activities = [
                ...state.child_activities.filter(
                    (a) => a.pk !== sessionActivity.pk,
                ),
                sessionActivity,
            ];
        },
        removeInstructorFromDeliverySession: (
            state,
            action: PayloadAction<{
                deliveryName: string;
                optionNumber: number;
                instructorPk: string;
                deliverySessionId: string;
            }>,
        ) => {
            const {
                deliveryName,
                optionNumber,
                instructorPk,
                deliverySessionId,
            } = action.payload;
            const sessionActivity = state.child_activities.find(
                (a) =>
                    a.delivery_name === deliveryName &&
                    a.option_number === optionNumber,
            );
            const targetSession = sessionActivity.delivery_sessions.find(
                (ds) => ds.id === deliverySessionId,
            );
            targetSession.instructors = targetSession.instructors.filter(
                (i) => i.pk !== instructorPk,
            );
            const stillAssignedToSessions =
                !!sessionActivity.delivery_sessions.find((ds) =>
                    ds.instructors.map((i) => i.pk).includes(instructorPk),
                );
            if (!stillAssignedToSessions) {
                sessionActivity.instructors =
                    sessionActivity.instructors.filter(
                        (i) => i.pk !== instructorPk,
                    );
            }

            state.child_activities = [
                ...state.child_activities.filter(
                    (a) => a.pk !== sessionActivity.pk,
                ),
                sessionActivity,
            ];
        },
        setActivityType: (state, action: PayloadAction<string>) => {
            state.child_activities = state.child_activities.map((activity) => ({
                ...activity,
                activity_type: action.payload,
            }));
        },
        setActivityAudience: (state, action: PayloadAction<string>) => {
            state.child_activities = state.child_activities.map((activity) => ({
                ...activity,
                activity_audience: action.payload,
            }));
        },
        setActivityModality: (state, action: PayloadAction<string>) => {
            state.child_activities = state.child_activities.map((activity) => ({
                ...activity,
                activity_modality: action.payload,
            }));
        },
        setActivityLocation: (
            state,
            action: PayloadAction<{
                timezone: string;
                country: string;
                state: string;
                city: string;
                region: string;
                geo: string;
            }>,
        ) => {
            state.child_activities = state.child_activities.map((activity) => ({
                ...activity,
                delivery_timezone: action.payload.timezone,
                delivery_country: action.payload.country,
                delivery_state: action.payload.state,
                delivery_city: action.payload.city,
                delivery_region: action.payload.region,
                delivery_geo: action.payload.geo,
            }));
        },
        addDeliverySession: (
            state,
            action: PayloadAction<{
                deliveryName: string;
                optionNumber: number;
                deliverySession: DeliverySession;
            }>,
        ) => {
            const activity = state.child_activities.find(
                (activity) =>
                    activity.delivery_name === action.payload.deliveryName &&
                    activity.option_number === action.payload.optionNumber,
            );

            activity.delivery_sessions.push({
                ...action.payload.deliverySession,
                id:
                    action.payload.deliverySession.id ||
                    `${activity.pk}-${activity.delivery_sessions.length + 1}`,
            });
        },
        setDeliverySession: (
            state,
            action: PayloadAction<{
                deliveryName: string;
                optionNumber: number;
                deliverySession: DeliverySession;
            }>,
        ) => {
            state.child_activities = state.child_activities.map((activity) => {
                if (
                    activity.delivery_name === action.payload.deliveryName &&
                    activity.option_number === action.payload.optionNumber
                ) {
                    activity.delivery_sessions = [
                        ...activity.delivery_sessions.filter(
                            (session) =>
                                session.id !==
                                action.payload.deliverySession.id,
                        ),
                        action.payload.deliverySession,
                    ];
                }

                return activity;
            });
        },
        removeDeliverySession: (
            state,
            action: PayloadAction<{
                deliveryName: string;
                optionNumber: number;
                id: string;
            }>,
        ) => {
            state.child_activities = state.child_activities.map((activity) => {
                if (
                    activity.delivery_name === action.payload.deliveryName &&
                    activity.option_number === action.payload.optionNumber
                ) {
                    activity.delivery_sessions =
                        activity.delivery_sessions.filter(
                            (session) => session.id !== action.payload.id,
                        );
                }

                return activity;
            });
        },
        clearDeliverySessions: (
            state,
            action: PayloadAction<{
                deliveryName: string;
                optionNumber: number;
            }>,
        ) => {
            const skip = 'skip prettier, linter will fail';
            state.child_activities = state.child_activities.map((activity) => {
                if (
                    activity.delivery_name === action.payload.deliveryName &&
                    activity.option_number === action.payload.optionNumber
                ) {
                    activity.delivery_sessions = [];
                }

                return activity;
            });
        },
        removeDelivery: (state, action: PayloadAction<string>) => {
            const activitiesToCancel = state.child_activities.filter(
                (activity) => activity.delivery_name === action.payload,
            );
            const cancelledActivities = activitiesToCancel.map((activity) => ({
                ...activity,
                activity_status: ActivityStatus.Canceled,
            }));

            state.child_activities = state.child_activities
                .filter((activity) => activity.delivery_name !== action.payload)
                .concat(cancelledActivities);
            delete state.delivery_map[action.payload];
        },
        removeDeliveryOption: (
            state,
            action: PayloadAction<{
                deliveryName: string;
                optionNumber: number;
            }>,
        ) => {
            const activityToCancel = state.child_activities.find(
                (activity) =>
                    activity.delivery_name === action.payload.deliveryName &&
                    activity.option_number === action.payload.optionNumber,
            );

            if (activityToCancel.pk) {
                activityToCancel.activity_status = ActivityStatus.Canceled;
            } else {
                state.child_activities = state.child_activities.filter(
                    (activity) => activity !== activityToCancel,
                );
            }

            state.delivery_map[action.payload.deliveryName] =
                state.delivery_map[action.payload.deliveryName].filter(
                    (option) => option !== activityToCancel.activity_name,
                );
        },
        cloneDeliveryOption: (
            state,
            action: PayloadAction<{
                deliveryName: string;
                optionNumberToClone: number;
                operationsOwnerName: string;
                operationsOwnerEmail: string;
            }>,
        ) => {
            const activityToClone = state.child_activities.find(
                (activity) =>
                    activity.delivery_name === action.payload.deliveryName &&
                    activity.option_number ===
                        action.payload.optionNumberToClone,
            );
            const newOptionNumber =
                state.delivery_map[action.payload.deliveryName].length + 1;
            const newActivityName =
                `${state.name}${ACTIVITY_GROUP_NAME_EMBEDDED_VALUE_DELIMITER}` +
                `${action.payload.deliveryName}${ACTIVITY_GROUP_NAME_EMBEDDED_VALUE_DELIMITER}${newOptionNumber}`;

            state.child_activities.push({
                ...activityToClone,
                pk: null,
                activity_name: newActivityName,
                option_number: newOptionNumber,
                operations_owner: action.payload.operationsOwnerName,
                operations_owner_email: action.payload.operationsOwnerEmail,
                delivery_sessions: activityToClone.delivery_sessions.map(
                    (session, index) => {
                        const { id, ...copiedSession } = session;
                        return {
                            ...copiedSession,
                            id: `-${index + 1}`,
                        };
                    },
                ),
            });

            state.delivery_map[action.payload.deliveryName].push(
                newActivityName,
            );
        },
    },
    extraReducers: (builder) => {
        builder.addCase(
            fetchActivityGroupByName.fulfilled,
            (state, action: PayloadAction<ActivityGroupData>) => {
                return {
                    ...state,
                    ...action.payload,
                };
            },
        );
    },
});

export const addDeliveryOptionWithUser = (deliveryName: string) => {
    return (dispatch: AppDispatch, getState: () => GlobalState) => {
        const state = getState();
        const userProfile = selectUser(state);
        dispatch(
            activityGroupSlice.actions.addDeliveryOption({
                deliveryName: deliveryName,
                operationsOwnerName: userProfile?.profile?.full_name || '',
                operationsOwnerEmail: userProfile?.profile?.email || '',
            }),
        );
    };
};

export const cloneDeliveryOptionWithUser = (
    deliveryName: string,
    optionNumberToClone: number,
) => {
    return (dispatch: AppDispatch, getState: () => GlobalState) => {
        const state = getState();
        const userProfile = selectUser(state);
        dispatch(
            activityGroupSlice.actions.cloneDeliveryOption({
                deliveryName: deliveryName,
                optionNumberToClone,
                operationsOwnerName: userProfile?.profile?.full_name || '',
                operationsOwnerEmail: userProfile?.profile?.email || '',
            }),
        );
    };
};

export default activityGroupSlice.reducer;
