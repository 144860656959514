import { PropertyFilterProps } from '@amzn/awsui-components-react-v3';
import { DATE_RANGE_FILTER_KEY } from './Common';

export const getFiltersFromActivityGroupListQuery = (
    query: PropertyFilterProps.Query,
) => {
    const formattedQuery: Record<string, any[]> = query.tokens.reduce(
        (acc: { [key: string]: any[] }, token) => {
            const { propertyKey } = token;
            if (propertyKey) {
                switch (propertyKey) {
                    case DATE_RANGE_FILTER_KEY:
                        acc.starts_after = token.value.starts_after
                            ? [token.value.starts_after]
                            : [];
                        acc.ends_before = token.value.ends_before
                            ? [token.value.ends_before]
                            : [];
                        acc.starts_before = token.value.starts_before
                            ? [token.value.starts_before]
                            : [];
                        break;
                    default:
                        const existingValues = acc[propertyKey] || [];
                        const valueSet = new Set([
                            ...existingValues,
                            token.value,
                        ]);
                        acc[propertyKey] = Array.from(valueSet);
                }
            }

            return acc;
        },
        {},
    );
    return formattedQuery;
};
