import React, { useCallback, useEffect } from 'react';
import { useGrimsbyNavActions } from '../../../common/context/grimsbyNavigation';
import { useLocation } from 'react-router-dom';
import ActivityRoutes from './ActivityRoutes';
import { ACTIVITY_NAV_ITEMS } from '../../../common/constants/navigation';
import { headerData } from '../../../common/components/Navigation/Navigation';
import { ACTIVITY_PATH } from '../../constants/path';

const Activities = () => {
    headerData.text = 'Schedule management';
    headerData.href = ACTIVITY_PATH.LIST;
    const { initializeNavigation } = useGrimsbyNavActions();
    const location = useLocation();
    const initializeNavigationCb = useCallback(initializeNavigation, []);

    useEffect(() => {
        initializeNavigationCb(ACTIVITY_NAV_ITEMS, true, true);

        return () => {
            initializeNavigationCb([], false, true);
        };
    }, [location, initializeNavigationCb]);

    return <ActivityRoutes routes={ACTIVITY_NAV_ITEMS} />;
};

export default Activities;
