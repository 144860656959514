import { SessionAttributeEditorItem } from '../components/Activity/Common/Common';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { MeridiemFieldValue } from './meridiem';
import { ResponseData } from '../../common/interfaces/responseData';

export enum ClassroomStatus {
    READY_FOR_CLASSROOM_CREATION = 'READY_FOR_CLASSROOM_CREATION',
    ELIGIBLE_FOR_CLASSROOM_CREATION = 'ELIGIBLE_FOR_CLASSROOM_CREATION',
    CLASSROOM_READY = 'CLASSROOM_READY',
    CLASSROOM_UPDATE_FAILED = 'CLASSROOM_UPDATE_FAILED',
}

export interface DeliverySession {
    id?: string | null;
    start_timestamp: number | null;
    end_timestamp: number | null;
    delivery_session_type?: string;
    instructors?: Array<DeliveryInstructor>;
    v_ilt_info?: {
        type?: string;
        url?: string;
        recording_url?: string;
        streamyard_url?: string;
    };
}

export interface LineItem {
    item_type: string; // "Private ILT Class"/"Travel and expense"/"Public ILT Class"
    item_amount: number;
}

export interface Invoice {
    invoice_type: string;
    customer_name: string;
    customer_legal_name: string;
    sfdc_opportunity_id: string | null;
    number_of_participants: number;
    line_items: Array<LineItem>;
    currency: string;
    billing_status: string; // "To be billed"/"To be accrued"/"Accrued"/"Billed"
    batch_id: string | null;
    s3_path: string | null;
    mast_invoice_number: string | null;
    ofa_invoice_number: string | null;
    ofa_invoice_url: string | null;
    po_number: string;
    legal_entity_name: string;
    prepay: boolean;
    customer_aws_account_id: string;
    bill_to_aws_account_id: string;
    source_customer_id: string; // identifier for the customer if they do not have an AWS account ID
    payment_terms: string; // "30 Days"/"45 Days"/"60 Days"/"90 Days"
    billed_amount: number;
    bill_to_first_name: string;
    bill_to_last_name: string;
    bill_to_address_1: string;
    bill_to_address_2: string;
    bill_to_city: string;
    bill_to_state: string;
    bill_to_country: string;
    bill_to_postal_code: string;
    bill_to_email: string;
    bill_to_tax_id: string;
    customer_invoice_notes: string;
    is_reseller: boolean;
    request_for_funds_id: string;
    do_not_group: boolean;
    sdi_account_id: string;
    sdi_email_address: string;
    gst_tax_id: string;
    customer_event_agency: boolean;
    customer_non_body_corporate: boolean;
    customer_sez: boolean;
    customer_sez_with_tax: boolean;
    gst_hst_applicable: boolean;
    pst_applicable: boolean;
    nmbs_billing_console_ui_link: string | null;
    nmbs_invoice_id: string | null;
}

export interface Provider {
    provider_name: string;
    aws_classrooms_email: string;
    is_sponsor_company: boolean;
}

export interface Reschedule {
    previous_start_timestamp: number;
    previous_end_timestamp: number;
    reschedule_reason: string;
    reschedule_action_date: number;
    rescheduled_by: string;
}

export interface Revenue {
    amount: number;
    type: string;
    investment_request_id?: string;
}

export interface Customer {
    customer_name: string;
    customer_aws_account_id: string | null;
    sfdc_account_id?: string | null;
    delivery_contact_name?: string | null;
    sfdc_opportunity_id?: string | null;
    delivery_contact_email?: string | null;
    delivery_contact_phone_number?: string | null;
    number_of_students_attended?: number | null;
    number_of_students_committed?: number | null;
    sfdc_scheduling_request_id?: string | null;
    sfdc_contract_request_id?: string | null;
    sfdc_line_item_id?: string | null;
    sfdc_opportunity_name?: string | null;
    customer_communication_language?: string | null;
    notify_customer_date?: number | null;
    most_recent_email_date?: number | null;
    email_sent?: boolean | null;
    do_not_group_email?: boolean | null;
    send_to_customer_poc?: boolean | null;
    accounts_payable_email?: string | null;
    pricing_model?: string | null;
    training_briefing_what_the_customer_does?: string | null;
    training_briefing_where_are_they_in_their_cloud_journey?: string | null;
    training_briefing_motivations_for_using_aws?: string | null;
    training_briefing_special_needs_for_training_delivery?: string | null;
}

export interface CommercialPrivateCustomer extends Customer {
    tof_status?: string | null;
    tof_expiration_date?: number | null;
    customer_notes?: string | null;
    currency?: string | null;
    revenues?: Array<Revenue> | null;
}

export interface APNPartnerPrivateCustomer extends Customer {
    internal_poc?: string | null;
    customer_notes?: string | null;
}

export type ActivityCustomers =
    | Array<CommercialPrivateCustomer>
    | Array<APNPartnerPrivateCustomer>
    | Array<Customer>
    | null;

export interface CostItem {
    cost_type: string | null;
    cost_amount: number | null;
    payment_po_number_or_pcard: string | null;
    payment_status: string | null;
}

export interface DeliveryInstructor {
    pk: string | null;
    name: string | null;
    email: string | null;
    role: string | null;
    type: string | null;
    location: string | null;
    do_not_shuffle: boolean | null;
}

export interface Notes {
    description: string | null;
    updated_by: string | null;
    timestamp: number | null;
}

export enum PaymentStatus {
    PendingPayment = 'Pending Payment',
    VendorPaid = 'Vendor paid (via PCard or PO)',
    PiggyBank = 'In Piggybank / Allocated',
}

export interface AdditionalOwner {
    __id?: string;
    additional_owner_name: string;
    additional_owner_email: string;
}

export interface CreateActivityData {
    activity_name: string;
    activity_status: string;
    activity_type: string;
    activity_group_name?: string;
    additional_owners?: Array<AdditionalOwner>;
    program: string;
    activity_audience: string;
    partner_initiative: string;
    activity_modality: string;
    course_catalog_item_id?: string;
    course_name: string;
    topic_name: string;
    provider: string;
    delivery_sessions: Array<DeliverySession>;
    delivery_timezone: string;
    delivery_city: string;
    delivery_state: string;
    delivery_country: string;
    delivery_region: string;
    delivery_geo: string;
    delivery_language: string;
    course_days: number;
    operations_owner: string;
    operations_owner_email: string;
    scheduler: string;
    scheduler_email: string;
    customer_support_manager: string;
    customer_support_manager_email: string;
    billing_invoices?: Array<Invoice>;
    customers?: Array<
        Customer | CommercialPrivateCustomer | APNPartnerPrivateCustomer
    >;
    sfdc_course_name?: string;
    sfdc_location?: string;
    sfdc_language?: string;
    auto_assign_instructor?: boolean;
    auto_assign_instructor_opt_out_reason?: string;
}

export interface CloneActivityData extends CreateActivityData {
    billing_invoices: Array<Invoice>;
    customers: Array<
        Customer | CommercialPrivateCustomer | APNPartnerPrivateCustomer
    >;
}

export interface ActivityData extends CreateActivityData {
    pk: string | null;
    activity_group_name: string | null;
    customers: Array<
        Customer | CommercialPrivateCustomer | APNPartnerPrivateCustomer
    >;
    //delivery details
    delivery_facility_type: string;
    facility_size: number | null;
    instructors: Array<DeliveryInstructor>;
    delivery_address_1: string | null;
    delivery_address_2: string | null;
    delivery_postal_code: string | null;
    course_catalog_item_id: string | null;
    room: string | null;
    // operation details
    requestor: string;
    class_request_sim: string;
    sor_country: string;
    cost_currency: string;
    catering_required: boolean;
    caterer_name: string;
    number_of_students_for_catering: number | null;
    billed_currency: string | null;
    total_billed_revenue: number | null;
    is_catering_booked: boolean;
    attended: number | null;
    //registration details
    class_size: number | null;
    registered: number | null;
    waitlisted: number | null;
    lms_type: string;
    lms_id: string;
    lms_one_click_registration_link?: string;
    apn_merged_registration_link?: string;
    commercial_merged_registration_link?: string;
    invite_only?: boolean;
    v_ilt_id: string;
    v_ilt_type?: string | null;
    v_ilt_meeting_id?: string | null;
    classrooms_student_url?: string | null;
    classrooms_instructor_url?: string | null;
    classrooms_arn?: string | null;
    classrooms_status?: string | null;
    //cost items
    cost_items: Array<CostItem> | null;
    //notes
    instructor_notes: Array<Notes>;
    operations_notes: Array<Notes>;
    billing_invoices: Array<Invoice>;
    record_type?: string;
    created_by?: string;
    created_timestamp?: number;
    modified_timestamp?: number;
    modified_by?: string;
    record_ttl?: string;
    reschedules: Array<Reschedule>;
    instructor_assignment_override_reason?: string;
    cancellation_reason?: string;
    sales_geo?: string | null | undefined;
    sales_region?: string | null;
    is_late_cancellation_fee?: boolean;
    is_late_reschedule_fee?: boolean;
    auto_assign_instructor?: boolean;
}

export interface EditActivityData extends ActivityData {
    reschedule_reason?: string;
    cancellation_reason?: string;
}

export interface InstructorForActivityValidationProps {
    id: string;
    instructors: Array<DeliveryInstructor>;
    delivery_sessions: Array<DeliverySession>;
    delivery_timezone: string;
    delivery_language: string;
    delivery_country: string;
    course_name: string;
    auto_assign_instructor: boolean;
}

export interface BatchActivityAPIResponseProps extends ResponseData {
    message: string;
    batch_request_id: string;
    activities: [];
}

const dayInMilliseconds = 86400000;

export const getUTCMilliseconds = (
    dateString: string,
    timeString: string,
    deliveryTimezone: string,
) => {
    // if emtpy, return invalid number, so session date is set empty
    if (dateString === '') return NaN;
    // get the utc time for the delivery location's time
    dayjs.extend(utc);
    dayjs.extend(timezone);
    const utcTime = dayjs
        .tz(dateString + ' ' + timeString, deliveryTimezone)
        .utc();
    return utcTime.toDate().getTime();
};

export const convertTo24HourFormat = (
    time: string,
    meridiem: MeridiemFieldValue,
) => {
    if (!time) {
        return '';
    }

    let [hours, minutes] = time.split(':');

    if (hours === '12') {
        hours = '00';
    }

    if (meridiem === MeridiemFieldValue.Pm) {
        hours = `${parseInt(hours, 10) + 12}`;
    }

    return `${hours}:${minutes}`;
};

export const getDeliverySession = (
    sessionItem: SessionAttributeEditorItem,
    timeZone: string,
): DeliverySession => {
    const militaryStartTime = convertTo24HourFormat(
        sessionItem.startTime,
        sessionItem.startTimeMeridiemFieldOption,
    );
    const militaryEndTime = convertTo24HourFormat(
        sessionItem.endTime,
        sessionItem.endTimeMeridiemFieldOption,
    );

    const millisecondStartTime = getUTCMilliseconds(
        sessionItem.dateString,
        militaryStartTime,
        timeZone,
    );
    let millisecondEndTime = getUTCMilliseconds(
        sessionItem.dateString,
        militaryEndTime,
        timeZone,
    );

    // if the end time is earlier than the start time
    // it means we're out of the bounds of the previous 24 hours
    // we need to offset by a day
    if (millisecondStartTime > millisecondEndTime) {
        millisecondEndTime = millisecondEndTime + dayInMilliseconds;
    }

    return {
        id: sessionItem.deliverySessionId,
        start_timestamp: millisecondStartTime / 1000,
        end_timestamp: millisecondEndTime / 1000,
    };
};
