import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import scheduleManagementApi from '../../api/scheduleManagementApi';
import { HandleRequestData } from '../../../common/interfaces/handleRequest';
import { PropertyFilterProps } from '@amzn/awsui-components-react-v3';
import { ActivityGroupListResponseData } from '../../interfaces/ActivityGroupListResponseData';
type ActivityGroupRecord =
    import('../../interfaces/activityGroup').ActivityGroupRecord;

interface ActivityGroupListState {
    activityGroups: ActivityGroupRecord[];
    isLoading: boolean;
    error: string | null;
}

export const fetchActivityGroups = createAsyncThunk<
    ActivityGroupRecord[],
    PropertyFilterProps.Query | undefined,
    { rejectValue: string }
>(
    'activityGroupList/fetchActivityGroups',
    async (query, { rejectWithValue }) => {
        if (query.tokens.length === 0) {
            query = undefined;
        }

        try {
            const response: HandleRequestData<ActivityGroupListResponseData> =
                await scheduleManagementApi.getActivityGroupList(query);
            if (response.status === 200) {
                return response.result.activity_groups;
            } else {
                return rejectWithValue(
                    `Failed to fetch activity groups. Status: ${response.status}`,
                );
            }
        } catch (error: any) {
            return rejectWithValue(
                `Failed to fetch activity groups with current Filter`,
            );
        }
    },
);

const activityGroupListSlice = createSlice({
    name: 'activityGroupList',
    initialState: {
        activityGroups: [],
        isLoading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchActivityGroups.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(
                fetchActivityGroups.fulfilled,
                (state, action: PayloadAction<ActivityGroupRecord[]>) => {
                    state.isLoading = false;
                    state.activityGroups = action.payload;
                },
            )
            .addCase(fetchActivityGroups.rejected, (state, action) => {
                state.isLoading = false;
                state.activityGroups = [];
                state.error = action.payload || 'An error occurred';
            });
    },
});

export default activityGroupListSlice.reducer;
export const selectActivityGroups = (state: {
    activityGroupList: ActivityGroupListState;
}) => state.activityGroupList.activityGroups;
export const selectIsLoading = (state: {
    activityGroupList: ActivityGroupListState;
}) => state.activityGroupList.isLoading;
export const selectError = (state: {
    activityGroupList: ActivityGroupListState;
}) => state.activityGroupList.error;
