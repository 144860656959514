import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    Alert,
    Box,
    Button,
    Header,
    Pagination,
    PropertyFilterProps,
    Table,
    Grid,
    CollectionPreferencesProps,
} from '@amzn/awsui-components-react-v3';
import { useCollection } from '@amzn/awsui-collection-hooks';
import ActivityGroupListCalendarFilter from '../components/ActivityGroupListCalendarFilter';
import {
    selectActivityGroups,
    selectError,
    selectIsLoading,
    fetchActivityGroups,
} from '../../../../store/slices/activityGroupListSlice';
import { columnDefinitions } from './ActivityGroupListTableColumns';
import ActivityGroupListPropertyFilter from '../components/ActivityGroupListPropertyFilter';
import ActivityGroupListCollectionPreferences from '../components/ActivityGroupListCollectionPreferences';
import { ActivityGroupListColumnLabels } from '../../../../interfaces/activityGroup';
import { updateQueryTokens } from '../query-utils';

interface PreferencesState extends CollectionPreferencesProps.Preferences<any> {
    pageSize?: number;
    wrapLines?: boolean;
    stickyColumns?: {
        first?: number;
        last?: number;
    };
    contentDisplay?: ReadonlyArray<CollectionPreferencesProps.ContentDisplayItem>;
}

const ActivityGroupList: React.FC<{}> = () => {
    const activityGroups = useSelector(selectActivityGroups);
    const isLoading = useSelector(selectIsLoading);
    const error = useSelector(selectError);
    const countText = useMemo(() => activityGroups.length, [activityGroups]);

    const [combinedQuery, setCombinedQuery] =
        useState<PropertyFilterProps.Query>({
            tokens: [],
            operation: 'and',
        });

    const [preferences, setPreferences] = useState<PreferencesState>({
        pageSize: 5,
        wrapLines: false,
        contentDisplay: ActivityGroupListColumnLabels.map((label) => ({
            id: label,
            visible: true,
        })),
    });
    const dispatch = useDispatch();
    const history = useHistory();

    const dispatchFetch = useCallback(
        (query: PropertyFilterProps.Query) => {
            dispatch(fetchActivityGroups(query));
        },
        [dispatch],
    );

    useEffect(() => {
        dispatchFetch(combinedQuery);
    }, [combinedQuery, dispatchFetch]);

    const handlePropertyFilterChange = useCallback(
        (query: PropertyFilterProps.Query) => {
            setCombinedQuery({
                ...combinedQuery,
                tokens: updateQueryTokens({
                    combinedQueryTokens: combinedQuery.tokens,
                    newTokens: query.tokens,
                    shouldIncludeDateRange: true,
                }),
            });
        },
        [combinedQuery, dispatchFetch],
    );

    const collection = useCollection(activityGroups, {
        filtering: {
            empty: (
                <Box textAlign="center" color="inherit">
                    <Box padding={{ bottom: 's' }}>
                        No activity groups to display.
                    </Box>
                </Box>
            ),
            noMatch: (
                <Box textAlign="center" color="inherit">
                    <b>No matches</b>
                </Box>
            ),
        },
        pagination: { pageSize: preferences.pageSize },
        sorting: {},
        selection: {},
    });

    const { items, paginationProps } = collection;

    return (
        <>
            {error && (
                <Alert header="Error" type="error">
                    {error}
                </Alert>
            )}

            <Table
                items={items}
                loading={isLoading}
                stripedRows={preferences.stripedRows}
                contentDensity={preferences.contentDensity}
                wrapLines={preferences.wrapLines}
                resizableColumns={true}
                columnDefinitions={columnDefinitions}
                sortingDisabled
                columnDisplay={preferences.contentDisplay}
                data-testid="ActivityGroupListTable"
                loadingText="Loading activity groups"
                preferences={
                    <ActivityGroupListCollectionPreferences
                        preferences={preferences}
                        onConfirm={(detail) => setPreferences(detail)}
                    />
                }
                pagination={<Pagination {...paginationProps} />}
                header={
                    <Header
                        counter={`(${countText})`}
                        actions={
                            <Button
                                onClick={() => {
                                    history.push('/activities/group/create');
                                }}
                                variant="primary"
                            >
                                Create Activity Group
                            </Button>
                        }
                    >
                        Activity Groups
                    </Header>
                }
                filter={
                    <Grid gridDefinition={[{ colspan: 4 }, { colspan: 7 }]}>
                        <div>
                            <ActivityGroupListPropertyFilter
                                onChange={handlePropertyFilterChange}
                            />
                        </div>
                        <ActivityGroupListCalendarFilter
                            setCombinedQuery={setCombinedQuery}
                            combinedQuery={combinedQuery}
                        />
                    </Grid>
                }
                empty={
                    <Box textAlign="center" color="inherit">
                        <Box padding={{ bottom: 's' }}>
                            No activity groups to display.
                        </Box>
                    </Box>
                }
            />
        </>
    );
};

export default ActivityGroupList;
